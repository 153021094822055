const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    // api: "https://localhost:5001/",
    api: "https://quyhoachpleiku-api.gialai.gov.vn/",
    apiRs: "https://paht-pleiku.cgis.asia/",
    media_url: "https://quyhoachpleiku-api.gialai.gov.vn/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "https://quyhoachpleiku.gialai.gov.vn",
    domainName: "",
    workSpace: "pleiku",
    //wmsBaseLink: "https://geo.cgis.asia/geoserver/pleiku/wms/",
    wmsBaseLink: "https://quyhoachpleiku-map.gialai.gov.vn/geoserver/pleiku/wms/",
  },
  production: {
    api: "https://quyhoachpleiku-api.gialai.gov.vn/",
    apiRs: "https://paht-pleiku.cgis.asia/",
    media_url: "https://quyhoachpleiku-api.gialai.gov.vn/",
    domainAdminSide: "https://quyhoachpleiku-admin.gialai.gov.vn",
    domainUserSide: "https://quyhoachpleiku.gialai.gov.vn",
    domainName: "quyhoachpleiku-admin.gialai.gov.vn",
    workSpace: "pleiku",
    wmsBaseLink: "https://quyhoachpleiku-map.gialai.gov.vn/geoserver/pleiku/wms/",
  },
};

module.exports = apiEnvironment[env];

// const env = process.env.NODE_ENV || "development";

// const apiEnvironment = {
//   development: {
//     api: "https://pleiku-api.cgis.asia/",
//     // api: "https://quyhoachpleiku-api.gialai.gov.vn/",
//     apiRs: "https://paht-pleiku.cgis.asia/",
//     media_url: "https://pleiku-api.cgis.asia/",
//     domainAdminSide: "http://localhost:3000",
//     domainUserSide: "https://pleiku.cgis.asia",
//     domainName: "",
//     workSpace: "pleiku",
//     wmsBaseLink: "https://geo.cgis.asia/geoserver/pleiku/wms/",
//     // wmsBaseLink: "https://quyhoachpleiku-map.gialai.gov.vn/geoserver/pleiku/wms/",
//   },
//   production: {
//     api: "https://pleiku-api.cgis.asia/",
//     apiRs: "https://paht-pleiku.cgis.asia/",
//     media_url: "https://pleiku-api.cgis.asia/",
//     domainAdminSide: "https://admin.pleiku.cgis.asia",
//     domainUserSide: "https://pleiku.cgis.asia",
//     domainName: "pleiku.cgis.asia",
//     workSpace: "pleiku",
//     wmsBaseLink: "https://geo.cgis.asia/geoserver/pleiku/wms/",
//   },
// };

// module.exports = apiEnvironment[env];